import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import image from '../../assets/images/items/3.gif';
import image1 from '../../assets/images/avatar/1.jpg';
import image3 from '../../assets/images/items/2.gif';
import image4 from '../../assets/images/items/1.jpg';
import image5 from '../../assets/images/items/2.jpg';
import image6 from '../../assets/images/items/1.gif';
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'
import Switcher from '../../components/switcher';
import { data } from '../../data/data';
import Home1 from '../../assets/images/home-2.png'
import Home2 from '../../assets/images/home-1.png'
import Home3 from '../../assets/images/home-3.jpg'
import Kinfra1 from '../../assets/images/kinfra-1.jpg'
import Kinfra2 from '../../assets/images/kinfra-2.jpg'
import Logo from '../../assets/images/kifo-icon.png'
import SkynicheLogo from '../../assets/images/skyniche-logo.png'
import FeroMatch from '../../assets/images/fero-match-logo.png'
import IconPlastics from '../../assets/images/ICONN_PLASTICS.png'
import Skylark from '../../assets/images/skylark.png'
import GreenVein from '../../assets/images/green-vein.png'
import EastAsian from '../../assets/images/east-asian.png'
import {IoMdClose,BsExclamationOctagon, MdOutlineArrowForward} from "../../assets/icons/vander"
import Team from '../../components/team';

export default function HomeKifo() {
    const params = useParams();
    const id = params.id;
    const creater = data.find((creatorr) => creatorr.id === parseInt(id));

    const [activeIndex, setIndex] = useState(0);
    const [placeBid, setPlaceBid] = useState(false);
    const [buyNow, setBuyNow] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    const images = [Home1, Home2, Home3, Kinfra1, Kinfra2, Logo, SkynicheLogo, FeroMatch, IconPlastics, Skylark, GreenVein, EastAsian];

    useEffect(() => {
        let loadedImages = 0;
        images.forEach((src) => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                loadedImages += 1;
                if (loadedImages === images.length) {
                    setImagesLoaded(true);
                }
            };
        });
        document.documentElement.classList.add('dark');
    }, []);

    // if (!imagesLoaded) {
    //     return <div style={{display:'flex',justifyContent:'center',height:'100vh'}} class="spinner"></div>
    // }

    return (
        <>
            <Navbar />
            <section className="relative lg:pt-244 pt-[744px] overflow-hidden" id='home'>
                <div className="container-fluid relative overflow-hidden">
                    <div className="absolute top-0 start-0 w-full h-full z-0 pointer-events-none overflow-hidden">
                        <iframe title="iframe" src="https://player.vimeo.com/video/991567346?background=1&autoplay=1&loop=1&byline=0&title=0" className="absolute top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 w-screen h-[56.25vw] min-h-screen min-w-[177.77vw] responsive-iframe" style={{ width: '100%', height: '500px' }}></iframe>
                    </div>
                    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>

                    <div className="lg:pt-36 lg:pb-48 pt-24 pb-36 shadow dark:shadow-gray-800" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='class-mt' style={{ backdropFilter: 'blur(50px)', borderRadius: '5px', textAlign: 'center', padding: '15px' ,WebkitBackdropFilter: 'blur(40px)',}}>
                            <div>
                                <div className="relative mb-5">
                                    <h1 style={{ fontFamily: 'Metropolis,sans-serif' }} className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white"><span>KINFRA Industrial</span><br />Forum Ottapalam </h1>
                                </div>
                                <h2 style={{ fontFamily: 'Metropolis,sans-serif', fontSize: '20px' }} className="bg-gradient-to-l from-red-600 to-violet-600 text-transparent bg-clip-text text-2xl lg:text-3xl ">Interact. Innovate. Inspire</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" style={{ color: '#fff', backgroundColor: '#fff' }}></path>
                    </svg>
                </div>
            </div>
            <section className="relative pt-16 md:pb-24 pb-16" id='exploreKifo' style={{ backgroundImage: `url(${Home2})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                <div>
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px] mt-10">
                        <div className="lg:col-span-7 sm:col-span-12 lg:me-8" style={{ paddingLeft: '25px', paddingRight: '10px', textAlign: 'left' }}>
                            <h5 className='text-4xl lg:text-5xl' style={{ fontFamily: 'Metropolis,sans-serif', lineHeight: '1.75rem', fontWeight: '600', color: '#000', marginBottom: '25px' }}>A Platform Tailored for Success</h5>
                            <p className="mt-4" style={{ fontFamily: 'Metropolis,sans-serif', fontSize: '20px', lineHeight: '1.75rem', fontWeight: '400', color: '#000c' }}>Networking Hub: Immerse yourself in a rich network of professionals, entrepreneurs, and industry leaders. KIFO provides not just a space for networking but a community where collaboration flourishes, partnerships are forged, and collective success is celebrated..</p>
                            <p className="mt-4" style={{ fontFamily: 'Metropolis,sans-serif', fontSize: '20px', lineHeight: '1.75rem', fontWeight: '400', color: '#000c' }}>Knowledge Exchange: Stay ahead of the curve with our curated events, workshops, and seminars. KIFO is committed to keeping you informed about the latest trends, emerging technologies, and industry insights, ensuring that you are well-equipped for the challenges and opportunities ahead.</p>
                            <p className="mt-4" style={{ fontFamily: 'Metropolis,sans-serif', fontSize: '20px', lineHeight: '1.75rem', fontWeight: '400', color: '#000c' }}>Business Support: KIFO is more than just a network; it's a support system for your business. Access mentorship programs, funding opportunities, and state-of-the-art facilities within the KINFRA Industrial and Defence Park. Our goal is to empower your business with the resources it needs to thrive.</p>
                        </div>
                        <div className="lg:col-span-5 sm:col-span-12">
                            <img src={Kinfra2} className='img-res' style={{ width: "100%", height: '400px', objectFit: 'cover' }} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative pt-188 md:pb-24 pb-16" id='discoverKifo' style={{ backgroundImage: `url(${Home1})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                <div>
                    <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px] mt-10">
                        <div className="lg:col-span-5 sm:col-span-12">
                            <img src={Kinfra1} className='img-res' style={{ width: "100%", height: '400px', objectFit: 'cover' }} alt="" />
                        </div>
                        <div className="lg:col-span-7 sm:col-span-12 lg:ms-8 padding-new">
                            <h5 className='text-4xl lg:text-5xl' style={{ fontFamily: 'Metropolis,sans-serif', lineHeight: '1.75rem', fontWeight: '600', color: '#fff', marginBottom: '25px' }}>Experience the Pulse of Innovation </h5>
                            <p className="mt-4" style={{ fontFamily: 'Metropolis,sans-serif', fontSize: '20px', lineHeight: '1.75rem', fontWeight: '400', color: '#fffc' }}>Welcome to the epicenter of innovation, collaboration, and growth - KIFO. Nestled within the vibrant community of the KINFRA Industrial and Defence Park in Palakkad, Kerala, KIFO is more than an association; it's a dynamic catalyst propelling businesses to new heights. </p>
                            <p className="mt-4" style={{ fontFamily: 'Metropolis,sans-serif', fontSize: '20px', lineHeight: '1.75rem', fontWeight: '400', color: '#fffc' }}>At KIFO, we understand that innovation is the heartbeat of progress. Our platform serves as a hub where creativity meets expertise, and ideas are transformed into reality. Explore the various facets of our innovation ecosystem, from research and development initiatives to groundbreaking projects that shape the future of industries.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-16" id='members' style={{ backgroundImage: `url(${Home2})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                <Team />
            </section>
            <section className="relative pt-16 md:pb-24 pb-16" id='insideKifo' style={{ backgroundColor: '#e6e6e6', backgroundPosition: 'center', backgroundSize: 'cover' }}>
                <div className="md:col-span-8 sm:col-span-12 mt-8">
                    <div className="lg:col-span-7 sm:col-span-12 lg:ms-8 padding-new">
                        <h2 className='text-4xl lg:text-5xl' style={{ fontFamily: 'Metropolis,sans-serif', lineHeight: '1.75rem', fontWeight: '600', color: '#000', marginBottom: '15px' }}>Inside Kinfra Industrial Forums</h2>
                        <p className="mt-10" style={{ fontFamily: 'Metropolis,sans-serif', fontSize: '20px', lineHeight: '1.75rem', fontWeight: '400', color: '#000c' }}>
                            Explore our website to discover upcoming events, success stories, and the transformative projects that are shaping the future. KIFO is not just about the present; it's about envisioning and creating a future where industries flourish, technologies evolve, and businesses excel. Whether you're an established industry leader or a budding entrepreneur, KIFO invites you to be part of a community that values innovation, collaboration, and success. Together, let's navigate the exciting journey of industrial growth, turning challenges into opportunities and dreams into reality. Embrace the future with KIFO - Where Innovation Meets Opportunity, and Success is a Collective Achievement.
                        </p>
                    </div>
                    <div className='p-666'>
                        <div className="bg-gray-50 p-66" style={{ borderRadius: '17px' }}>
                            <div className="grid md:grid-cols-12 sm:grid-cols-1 gap-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className="md:col-span-4 sm:col-span-12" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={Logo} alt="Image description" className="w-full h-full" style={{ objectFit: 'contain' }} />
                                </div>
                                <div className="md:col-span-8 sm:col-span-12 mt-8 md:mt-0">
                                    <h2 className="md:text-3xl text-xl font-bold text-black padding-right-new" style={{ fontFamily: 'Metropolis,sans-serif' }}>Industries</h2>
                                    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-10">
                                        {[GreenVein, SkynicheLogo, FeroMatch, EastAsian, Skylark, IconPlastics].map((logo, index) => (
                                            <div key={index}>
                                                <img src={logo} alt={`Logo ${index + 1}`} style={{ width: '100%', height: '105px', objectFit: 'contain', marginBottom: '25px' }} />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex mt-12" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '70px' }}>
                                        <Link to="/industries" className="btn btn-link hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center" style={{ fontFamily: 'Metropolis,sans-serif', fontSize: '17px', lineHeight: '1.75rem', color: '#000c' }}>See More <MdOutlineArrowForward className="ms-1 text-base" /></Link>
                                        {/* <span className="text-slate-400 text-[16px]">from <Link to={/creator-profile/${ele.id}} className="text-slate-900 dark:text-white hover:text-violet-600 dark:hover:text-violet-600 font-medium">{ele.subtext}</Link></span> */}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
