import React from 'react';
import image from '../../assets/images/items/2.jpg';
import single from '../../assets/images/blog/single.jpg';
import image1 from '../../assets/images/blog/05.jpg';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import { Link, useParams } from 'react-router-dom';
import { LuClock, LuCalendarDays, MdOutlineArrowForward } from "../../assets/icons/vander";
import { industryData } from '../../data/data';
import NavbarIndustries from '../../components/navbar-industries';

export default function IndustryDetail() {
    const params = useParams();
    const title1 = params.title1;
    const industry = industryData.find((blog) => blog.title1 === title1);

    return (
        <>
            <NavbarIndustries />
            <section className="relative pt-32 md:pb-24 pb-16">
                <div className="container">
                    <div className="md:flex">
                        <div className="lg:w-5/5">
                            <h5 className="md:text-3xl text-violet-600 text-2xl md:leading-snug leading-snug" style={{fontFamily:'Metropolis,sans-serif',lineHeight:'1.75rem',fontWeight:'600',}}>{industry?.title ? industry?.title : "TOKEN2040: The Future of Crypto, Decrypted"}</h5>
                            <p className="text-lg text-white mt-4" style={{fontFamily:'Metropolis,sans-serif',}}>{industry?.description ? industry?.description : "TOKEN2040: The Future of Crypto, Decrypted"}</p>
                            <p className="text-lg text-white mt-4" style={{fontFamily:'Metropolis,sans-serif',}}>{industry?.description1 ? industry?.description1 : "TOKEN2040: The Future of Crypto, Decrypted"}</p>
                            {industry?.services && (
                                <div className="lg:w-5/5 mt-5">
                                    <h5 className="md:text-3xl text-violet-600 text-2xl md:leading-snug leading-snug font-semibold">{industry?.services ? industry?.services : "TOKEN2040: The Future of Crypto, Decrypted"}</h5>
                                    <div className="service-points-container mt-4">
                                        {industry?.servicePoints.map((ele, index) => (
                                            <div className="service-point" key={index}>
                                                <li className="flex items-center mt-2" style={{fontFamily:'Metropolis,sans-serif',fontSize:'18px'}}>
                                                    <MdOutlineArrowForward className="text-violet-600 text-base align-middle me-2"/>
                                                    {ele}
                                                </li>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <div className="flex mt-6">
                                {industry?.website && (
                                    <Link to={industry?.website} target="_blank" className="font-medium inline-flex items-center">
                                        <span className="me-1 mdi--web-check" style={{fontSize:'1.3rem'}}/>
                                        {"Visit our website"}
                                    </Link>
                                )}
                                {/* <span style={{marginLeft:'10px'}} className="font-medium inline-flex items-center"><LuClock className="me-2"/>5 min read</span> */}
                            </div>
                        </div>
                    </div>

                    <div className="md:flex justify-center mt-6">
                        <div className="lg:w-full">
                            <div className="relative">
                                <img src={industry?.image ? industry?.image : single} className="rounded-lg shadow-md dark:shadow-gray-800 w-full h-auto" alt="" />
                                <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                    {/* <Link to="#" data-type="youtube" data-id="S_CGed6E610"
                                        className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-violet-600">
                                        <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <Switcher />
        </>
    );
}
