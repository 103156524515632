import React, { useState, useEffect } from 'react';
import image from '../assets/images/manoj.jpg';
import image1 from '../assets/images/Manaf.jpeg';
import image2 from '../assets/images/vineetKumar.jpeg';
import image3 from '../assets/images/Rakesh.jpg';
import image4 from '../assets/images/manu.jpg';
import image5 from '../assets/images/cyriac.jpg';
import image6 from '../assets/images/sujesh.png';
import image7 from '../assets/images/adheesh.jpg';
import image8 from '../assets/images/anuraj.jpg';
import { Link } from 'react-router-dom';
import { MdOutlineArrowForward } from 'react-icons/md';
import Home2 from '../assets/images/home-1.png';
import Switcher from './switcher';
import Footer from './footer';
import Navbar from './navbar';
import NavbarMembers from './navbar-members';

export default function Members() {
    const teamData = [
        { id: 1, image: image, name: "Manoj Karthikeyan", title: "President", designation:'Managing Director Green Vein' },
        { id: 2, image: image1, name: "Abdul Manaf T A", title: "Secretary", designation:'Managing Director East Asian Holdings' },
        { id: 3, image: image2, name: "Vineeth Kumar", title: "Treasurer", designation:'Managing Director Feromach Technology' },
        { id: 4, image: image3, name: "Rakesh Krishnan R", title: "Joint Secretary", designation: 'Founder & CEO skyniche Technologies' },
        { id: 5, image: image4, name: "Manu Nair", title: "Executive Member", designation:'Managing Director Iconn Plastics' },
        { id: 6, image: image5, name: "Cyriac Pius", title: "Executive Member", designation:'Managing Director Ozak' },
        { id: 7, image: image6, name: "Sujesh A V", title: "Executive Member", designation:'Managing Director Skylark Plastics' },
        { id: 8, image: image7, name: "Adheesh Thalekkara", title: "Member", designation: 'Founder and CEO Microclouds' },
        { id: 9, image: image8, name: "Anuraj", title: "Member", designation: 'Founder and CEO Saron Labs' },
    ];

    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        const images = teamData.map(member => member.image);
        let loadedImages = 0;

        images.forEach(src => {
            const img = new Image();
            img.src = src;
            img.onload = () => {
                loadedImages += 1;
                if (loadedImages === images.length) {
                    setImagesLoaded(true);
                }
            };
        });
    }, [teamData]);

    // if (!imagesLoaded) {
    //     return <div>Loading...</div>;
    // }

    return (
        <>
            <NavbarMembers />
            <section className="pb-16" id='members' style={{ backgroundImage: `url(${Home2})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-8">
                        <h1 className="lg:leading-snug leading-snug text-4xl lg:text-6xl text-black padding-right-new1 mt-24" style={{ textAlign: 'left', fontFamily: 'Metropolis,sans-serif', lineHeight: '1.75rem', fontWeight: '600', color: '#000' }}>Our Member Lineup</h1>
                    </div>

                    <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8" style={{ gap: '30px' }}>
                        {teamData.map((item, index) => {
                            const additionalMarginTop = (index % 3 === 1) ? 'mt-70' : (index % 3 === 2) ? 'mt-140' : '';

                            return (
                                <div className={`lg:col-span-1 hover-card2 ${additionalMarginTop}`} key={index}>
                                    <div className="group text-center">
                                        <div className="relative inline-block mx-auto overflow-hidden">
                                            <img src={item.image} className='class-image' alt="" />
                                        </div>

                                        <div className="content mt-3">
                                            <Link to="#" className="text-lg font-semibold hover:text-violet-600 text-black transition-all duration-500 ease-in-out" style={{ fontFamily: 'Metropolis,sans-serif', fontSize: '20px', lineHeight: '1.75rem' }}>{item.name}</Link>
                                            <p className="text-slate-400" style={{ fontFamily: 'Metropolis,sans-serif', fontSize: '20px', lineHeight: '1.75rem' }}>{item.title}</p>
                                            <p className="text-black" style={{ fontFamily: 'Metropolis,sans-serif', fontSize: '17px', lineHeight: '1.75rem' }}>{item.designation}</p>
                                        </div>

                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
            <Footer />
            <Switcher />
        </>
    );
}
