import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import Switcher from '../../components/switcher';
import { Link } from 'react-router-dom';
import { LuClock, MdOutlineArrowForward } from "../../assets/icons/vander";
import { industryData } from '../../data/data';
import KifoImg from '../../assets/images/kifo-4.jpg';

export default function Industry() {
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const imageRefs = useRef([]);

    useEffect(() => {
        // Function to check if all images are loaded
        const checkImagesLoaded = () => {
            const allImagesLoaded = imageRefs.current.every(img => img.complete);
            if (allImagesLoaded) {
                setImagesLoaded(true);
            }
        };

        // Set up event listeners for images
        const imageElements = document.querySelectorAll('img');
        imageElements.forEach(img => {
            img.addEventListener('load', checkImagesLoaded);
            img.addEventListener('error', checkImagesLoaded); // Handle error cases
        });

        // Check images on mount
        checkImagesLoaded();

        // Cleanup event listeners on unmount
        return () => {
            imageElements.forEach(img => {
                img.removeEventListener('load', checkImagesLoaded);
                img.removeEventListener('error', checkImagesLoaded);
            });
        };
    }, []);

    return (
        <>
            <Navbar />

            {imagesLoaded ? (
                <>
                    <section className="relative lg:pt-244 pt-[74px] overflow-hidden">
                        <div className="container-fluid relative overflow-hidden">
                            <div
                                className="absolute top-0 start-0 w-full h-full z-0 pointer-events-none overflow-hidden"
                                style={{ backgroundImage: `url(${KifoImg})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
                            ></div>
                            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                            <div className="lg:pt-36 lg:pb-48 pt-24 pb-36 shadow dark:shadow-gray-800">
                                <div className="container">
                                    <div className="grid grid-cols-1 justify-center text-center">
                                        <div className="relative">
                                            <div className="relative mb-5">
                                                <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Industries</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="relative" style={{ backgroundColor: '#fff', }}>
                        <div style={{ padding: '20px', display: 'flex', alignItems: 'stretch', justifyContent: 'stretch', alignContent: 'stretch', }}>
                            <div style={{ marginTop: '50px' }} className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-[30px]">
                                {industryData.map((ele, index) => (
                                    <div key={index} className="group relative overflow-hidden shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-100 " style={{ borderRadius: '4px', marginBottom: '40px' }}>
                                        <img
                                            src={ele.image}
                                            ref={el => (imageRefs.current[index] = el)}
                                            style={{ height: '283px', width: '100%', objectFit: 'cover' }}
                                            alt=""
                                        />

                                        <div className="relative p-6">
                                            <div className="absolute start-6 -top-4">
                                                <span className="bg-violet-600 text-white text-[12px] px-2.5 py-1 font-semibold rounded-full h-5">{ele.category}</span>
                                            </div>

                                            <div className="">
                                                <div className="flex">
                                                    {/* <span className="text-slate-400 text-[16px] inline-flex items-center"><LuCalendarDays className="text-slate-900 dark:text-white me-2"/>{ele.date}</span> */}
                                                    {/* <span className="text-slate-400 text-[16px] inline-flex items-center"><LuClock className="text-slate-900 dark:text-white me-2"/>5 min read</span> */}
                                                </div>

                                                {ele.id !== 7 ? (<Link to={`/industry-details/${ele.title1}`} className="hover:text-violet-600 duration-500 ease-in-out" style={{ fontFamily: 'Metropolis,sans-serif', fontSize: '20px', lineHeight: '1.75rem', color: '#000c' }}>{ele.title}</Link>) : <Link className="hover:text-violet-600 duration-500 ease-in-out" style={{ fontFamily: 'Metropolis,sans-serif', fontSize: '20px', lineHeight: '1.75rem', color: '#000c' }}>{ele.title}</Link>}

                                                <div className="flex justify-between mt-4">
                                                    {ele.id !== 7 ? (
                                                        <Link to={`/industry-details/${ele.title1}`} className="btn btn-link hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center" style={{ fontFamily: 'Metropolis,sans-serif', fontSize: '16px', lineHeight: '1.75rem', fontWeight: '200', color: '#000c' }}>Read More <MdOutlineArrowForward className="ms-1 text-base" /></Link>
                                                    ) : (
                                                        <Link className="btn btn-link text-black text-[16px] font-medium hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center">Coming Soon <MdOutlineArrowForward className="ms-1 text-base" /></Link>
                                                    )}
                                                    {/* <span className="text-slate-400 text-[16px]">from <Link to={`/creator-profile/${ele.id}`} className="text-slate-900 dark:text-white hover:text-violet-600 dark:hover:text-violet-600 font-medium">{ele.subtext}</Link></span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                            <div className="md:col-span-12 text-center">
                                <nav>
                                    <ul className="inline-flex items-center -space-x-px">
                                        <li>
                                            <Link to="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">
                                                <MdKeyboardArrowLeft className="text-[20px]" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">1</Link>
                                        </li>
                                        <li>
                                            <Link to="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">2</Link>
                                        </li>
                                        <li>
                                            <Link to="/" aria-current="page" className="z-10 w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-white bg-violet-600 shadow-sm dark:shadow-gray-700">3</Link>
                                        </li>
                                        <li>
                                            <Link to="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">4</Link>
                                        </li>
                                        <li>
                                            <Link to="/" className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600">
                                                <MdKeyboardArrowRight className="text-[20px]" />
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div> */}
                    </section>
                    <Footer />
                    <Switcher />
                </>
            ) : (
                <div className="flex justify-center items-center h-screen">
                    <p></p>
                </div>
            )}
        </>
    );
}
