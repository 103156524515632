import React, { useEffect, useState } from 'react';
import logo_icon_28 from '../assets/images/logo-icon-28.png';
import logo_dark from '../assets/images/logo-dark.png';
import logo_white from '../assets/images/logo-white.png';
import KifoLogo from '../assets/images/kifo-navbar-logo.png';
import image from '../assets/images/client/05.jpg';
import { Link, useNavigate } from "react-router-dom";
import { Link as ScrollLink, scroller } from 'react-scroll';
import { LuSearch, PiWalletBold, AiOutlineCopy, AiOutlineUser, LuSettings, LiaSignOutAltSolid } from "../assets/icons/vander";

export default function Navbar() {
    const [isDropdown, openDropdown] = useState(true);
    const [isOpen, setMenu] = useState(true);

    useEffect(() => {
        activateMenu();
    });

    window.addEventListener("scroll", windowScroll);
    function windowScroll() {
        const navbar = document.getElementById("topnav");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            if (navbar !== null) {
                navbar?.classList.add("nav-sticky");
            }
        } else {
            if (navbar !== null) {
                navbar?.classList.remove("nav-sticky");
            }
        }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }

    const toggleMenu = () => {
        setMenu(!isOpen)
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    }

    const getClosest = (elem, selector) => {

        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;

    };
    const activateMenu = () => {
        var menuItems = document.getElementsByClassName("sub-menu-item");
        if (menuItems) {

            var matchingMenuItem = null;
            for (var idx = 0; idx < menuItems.length; idx++) {
                if (menuItems[idx].href === window.location.href) {
                    matchingMenuItem = menuItems[idx];
                }
            }

            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');


                var immediateParent = getClosest(matchingMenuItem, 'li');

                if (immediateParent) {
                    immediateParent.classList.add('active');
                }

                var parent = getClosest(immediateParent, '.child-menu-item');
                if (parent) {
                    parent.classList.add('active');
                }

                var parent = getClosest(parent || immediateParent, '.parent-menu-item');

                if (parent) {
                    parent.classList.add('active');

                    var parentMenuitem = parent.querySelector('.menu-item');
                    if (parentMenuitem) {
                        parentMenuitem.classList.add('active');
                    }

                    var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                } else {
                    var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                }
            }
        }
    }

    const metamask = async () => {
        try {
            //Basic Actions Section
            const onboardButton = document.getElementById('connectWallet')

            //   metamask modal
            const modal = document.getElementById('modal-metamask')
            const closeModalBtn = document.getElementById('close-modal')

            //   wallet address
            const myPublicAddress = document.getElementById('myPublicAddress')

            //Created check function to see if the MetaMask extension is installed
            const isMetaMaskInstalled = () => {
                //Have to check the ethereum binding on the window object to see if it's installed
                const { ethereum } = window
                return Boolean(ethereum && ethereum.isMetaMask)
            }

            const onClickConnect = async () => {
                if (!isMetaMaskInstalled()) {
                    //meta mask not installed
                    modal.classList.add('show')
                    modal.style.display = 'block'
                    return
                }
                try {
                    // eslint-disable-next-line no-undef
                    await ethereum.request({ method: 'eth_requestAccounts' })
                    // eslint-disable-next-line no-undef
                    const accounts = await ethereum.request({ method: 'eth_accounts' })
                    myPublicAddress.innerHTML =
                        accounts[0].split('').slice(0, 6).join('') +
                        '...' +
                        accounts[0]
                            .split('')
                            .slice(accounts[0].length - 7, accounts[0].length)
                            .join('')
                } catch (error) {
                    console.error(error)
                }
            }

            const closeModal = () => {
                modal.classList.remove('show')
                modal.style.display = 'none'
            }

            if (isMetaMaskInstalled()) {
                // eslint-disable-next-line no-undef
                const accounts = await ethereum.request({ method: 'eth_accounts' })
                if (!!accounts[0]) {
                    myPublicAddress.innerHTML =
                        accounts[0].split('').slice(0, 6).join('') +
                        '...' +
                        accounts[0]
                            .split('')
                            .slice(accounts[0].length - 7, accounts[0].length)
                            .join('')
                }
            }

            onboardButton.addEventListener('click', onClickConnect)
            closeModalBtn.addEventListener('click', closeModal)
        } catch (error) { }
    }
    const navigate = useNavigate();

    const handleScrollLinkClick = (section) => {
        navigate('/');
        setTimeout(() => {
            scroller.scrollTo(section, {
                smooth: true,
                duration: 500,
            });
        }, 100); // Delay to ensure the home page has loaded
        setMenu(true); // Close the menu after clicking
    };

    return (
        <>
            <nav id="topnav" className="defaultscroll is-sticky" style={{borderBottom:'0.5px solid #ccc'}}>
                <div className="container">
                    {/* <!-- Logo container--> */}
                    <Link className="logo ps-0" to="/">
                        <img src={KifoLogo} className="inline-block sm:hidden h-77" alt="" />
                        <div className="sm:block hidden">
                            <img src={KifoLogo} className="inline-block dark:hidden h-77" alt="" />
                            <img src={KifoLogo} className="hidden dark:inline-block h-77" alt="" />
                        </div>
                    </Link>

                    {isOpen ?(<div>
                        <div style={{display:'flex',justifyContent:'flex-end'}}>
                            {/* <!-- Mobile menu toggle--> */}
                            <Link to="#" className="navbar-toggle" style={{backgroundColor:'#e6e6e6',padding:'8px',borderRadius:'6px',width:'fit-content',height:'fit-content',color:'#000048',marginTop:"22px"}}  id="isToggle" onClick={toggleMenu}>
                                <div className="eva--menu-2-outline" style={{fontSize:'25px',color:'#000048',marginBottom:'-6px',marginTop:'-1px'}}>
                                </div>
                            </Link>
                        </div>
                    </div>) : <div style={{display:'flex',justifyContent:'flex-end',}}>
                    <div style={{backgroundColor:'#e6e6e6',height:'fit-content',marginTop:'23px',padding:'5px',width:'fit-content',borderRadius:'6px'}}>
                        <div onClick={toggleMenu} style={{fontSize:'30px',color:'#000048',marginBottom:'-6px',marginTop:'-1px'}} className='line-md--close-small'/>
                        </div>
                        </div>}

                    <div id="navigation" className={isOpen === true ? 'hidden' : 'block'} style={{fontFamily:'Metropolis,sans-serif',}}>
                        <ul className="navigation-menu justify-end" style={{fontFamily:'Metropolis,sans-serif',}}>
                            <li style={{fontFamily:'Metropolis,sans-serif',}}>
                                <ScrollLink
                                    to="home"
                                    smooth={true}
                                    duration={500}
                                    style={{ cursor: 'pointer',fontFamily:'Metropolis,sans-serif', }}
                                    onClick={() => navigate('/')}
                                >
                                    Home
                                </ScrollLink>
                            </li>

                            <li className="has-submenu parent-menu-item" style={{fontFamily:'Metropolis,sans-serif',}}>
                                <Link to="#" style={{fontFamily:'Metropolis,sans-serif',}}>About </Link><span className="menu-arrow"></span>
                                <ul className="submenu" style={{fontFamily:'Metropolis,sans-serif',}}>
                                    <li><Link to="https://kinfra.org/" target="_blank" className="sub-menu-item" style={{fontFamily:'Metropolis,sans-serif',}}>Kinfra</Link></li>
                                    <li>
                                        <ScrollLink
                                            to="exploreKifo"
                                            smooth={true}
                                            duration={500}
                                            style={{ cursor: 'pointer',fontFamily:'Metropolis,sans-serif', }}
                                            onClick={() => handleScrollLinkClick('exploreKifo')}
                                        >
                                            Explore KIFO
                                        </ScrollLink>
                                    </li>
                                    <li>
                                        <ScrollLink
                                            to="discoverKifo"
                                            smooth={true}
                                            duration={500}
                                            style={{ cursor: 'pointer',fontFamily:'Metropolis,sans-serif', }}
                                            onClick={() => handleScrollLinkClick('discoverKifo')}
                                        >
                                            Discover KIFO
                                        </ScrollLink>
                                    </li>
                                    <li>
                                        <ScrollLink
                                            to="insideKifo"
                                            smooth={true}
                                            duration={500}
                                            style={{ cursor: 'pointer',fontFamily:'Metropolis,sans-serif', }}
                                            onClick={() => handleScrollLinkClick('insideKifo')}
                                        >
                                            Inside Kinfra
                                        </ScrollLink>
                                    </li>
                                </ul>
                            </li>
                            <li><Link style={{fontFamily:'Metropolis,sans-serif',}} to="/industries" className="sub-menu-item">Industries</Link></li>
                            <li>
                                <Link
                                    to="/members"
                                    // smooth={true}
                                    // duration={500}
                                    // onClick={() => handleScrollLinkClick('members')}
                                    style={{ cursor: 'pointer',fontFamily:'Metropolis,sans-serif', }}
                                >
                                    Members
                                </Link>
                            </li>
                            <li className="has-submenu parent-menu-item">
                                <Link to="#" style={{fontFamily:'Metropolis,sans-serif',}}>Download </Link><span className="menu-arrow"></span>
                                <ul className="submenu">
                                    <li><a href="/MEMBERSHIP_APPLICATION_TO_PRINT_1_240729_183426.pdf" className="sub-menu-item" style={{fontFamily:'Metropolis,sans-serif',}} download>Memebership form</a></li>
                                </ul>
                            </li>
                            <li><Link style={{fontFamily:'Metropolis,sans-serif',}} to="/contact" className="sub-menu-item">Contact us</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>

        </>
    )
}
