import React from 'react'
import image from '../assets/images/manoj.jpg';
import image1 from '../assets/images/Manaf.jpeg';
import image2 from '../assets/images/vineetKumar.jpeg';
import image3 from '../assets/images/Rakesh.jpg';
import image4 from '../assets/images/manu.jpg';
import image5 from '../assets/images/cyriac.jpg';
import image6 from '../assets/images/sujesh.png';
import { Link } from 'react-router-dom';
import { Facebook, Instagram, Linkedin } from 'react-feather';
import { MdOutlineArrowForward } from 'react-icons/md';
import TinySlider from 'tiny-slider-react';

const settings = {
    items: 1,
    controls: true,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    nav: false,
    speed: 400,
    gutter: 0,
    controlsText: ['<i class="grommet-icons--form-previous" style="margin-top:6px;margin-left:-2px;"></i>', '<i class="ic--twotone-navigate-next" style="margin-top:6px;margin-left:0px;"></i>']
};

export default function Team() {
    const teamData = [
        {
            id:1,
            image: image,
            name: "Manoj Karthikeyan",
            title: "President",
            designation:'Managing Director Green Vein'
        },
        {
            id:2,
            image: image1,
            name: "Abdul Manaf T A",
            title: "Secreatry",
            designation:'Managing Director East Asian Holdings'
        },
        {
            id:3,
            image: image2,
            name: "Vineeth Kumar",
            title: "Treasurer",
            designation:'Managing Director Feromach'
        },
        {
            id:4,
            image: image3,
            name: "Rakesh Krishnan R",
            title: "Joint Secreatry",
            designation:'Founder & CEO skyniche Technologies'
        },
        {
            id:5,
            image: image4,
            name: "Manu Nair",
            title: "Executive Member",
            designation:'Managing Director Iconn Plastics'
        },
        {
            id:6,
            image: image5,
            name: "Cyriac Pius",
            title: "Executive Member",
            designation:'Managing Director Ozak'
        },
        {
            id:7,
            image: image6,
            name: "Sujesh A V",
            title: "Executive Member",
            designation:'Managing Director Skylark Plastics'
        },
        {
            id:8,
        },
    ]
    return (
        <div className="container" >
            <div className="grid grid-cols-1 pb-8 text-center">
                <h1 className="lg:leading-snug leading-snug text-4xl lg:text-6xl text-black padding-right-new1 mt-24" style={{textAlign:'left',fontFamily:'Metropolis,sans-serif',lineHeight:'1.75rem',fontWeight:'600',color:'#000',}}>Our Executive Members</h1>
            </div>

            <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8" style={{gap:'30px'}}>
                {teamData.map((item, index) => {
                    let additionalMarginTop = '';
                    if (index === 1) {
                        additionalMarginTop = '70px';
                    } else if (index === 2) {
                        additionalMarginTop = '140px';
                    }else if (index === 4) {
                        additionalMarginTop = '70px';
                    }else if (index === 5) {
                        additionalMarginTop = '140px';
                    }
                    else if (index === 7) {
                        additionalMarginTop = '70px';
                    }

                    return (
                        <div className={item.id !==8 ? `lg:col-span-1 hover-card` : `lg:col-span-1 hover-card1`} style={{marginTop:additionalMarginTop}} key={index}>
                            <div className="group text-center">
                                <div className="relative inline-block mx-auto overflow-hidden">
                                    {item.id !== 8 && (<img src={item.image} className='class-image' alt="" />)}
                                    {item.id === 8 && (
                                    <div style={{ width: '365px', height: '425px', position: 'relative',border:'1px solid #ccc',justifyContent:'center' }}>
                                    <div className="p-6" style={{ display: 'flex', justifyContent: 'center',flexDirection:'column', fontSize: '24px',alignContent:'center' }}>
                                           <span style={{fontFamily:'Metropolis,sans-serif',fontSize:'92px',color:'#910037',marginTop:'60px',marginLeft:'-20px',}}>+20</span>
                                           <span style={{fontFamily:'Metropolis,sans-serif',fontSize:'48px',color:'#999999',marginTop:'50px',textAlign:'center',marginLeft:'10px',lineHeight:'1.2'}}>Visionary Entrepreneurs</span>
                                    </div>
                                    <div style={{ fontSize: '16px', textAlign: 'center', position: 'absolute', bottom: '40px', width: '100%' }}>
                                     <Link to={`/members`} className="btn btn-link hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out inline-flex items-center" style={{fontFamily:'Metropolis,sans-serif',fontSize:'22px',lineHeight:'1.75rem',color:'#000c',}}>See More <MdOutlineArrowForward style={{fontSize:'22px'}} className="ms-1 text-base"/></Link>
                                    </div>
                                </div>
                                    )}
                                </div>

                                <div className="content mt-3">
                                    <Link to="#" className="text-lg font-semibold hover:text-violet-600 text-black transition-all duration-500 ease-in-out" style={{fontFamily:'Metropolis,sans-serif',fontSize:'20px',lineHeight:'1.75rem',}}>{item.name}</Link>
                                    <p className="text-slate-400" style={{fontFamily:'Metropolis,sans-serif',fontSize:'20px',lineHeight:'1.75rem',}}>{item.title}</p>
                                    <p className="text-black" style={{fontFamily:'Metropolis,sans-serif',fontSize:'17px',lineHeight:'1.75rem',}}>{item.designation}</p>
                                </div>
                                
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="md:col-span-5 relative phone-image" style={{marginTop:'-30px'}}>
                <div className="tiny-one-icon-item">
                    <TinySlider settings={settings}>
                        {teamData.map((el, index) => (
                            <>
                            {el.id !== 8 &&(<div className="tiny-slide" key={index}>
                                <div className="m-2 bg-white rounded-lg">
                                    <Link className="group relative overflow-hidden rounded-lg">
                                        <img src={el.image} style={{width:'100%',height:'480px',objectFit:'cover'}} className="rounded-lg" alt="" />

                                        <span className="absolute bottom-5 start-5" style={{backdropFilter:'blur(40px)',padding:'8px',borderRadius:'5px',WebkitBackdropFilter: 'blur(40px)',}}>

                                            <span style={{display:'flex',flexDirection:'column',whiteSpace:'nowrap'}}>
                                                <Link to="#" className="text-lg font-semibold hover:text-violet-600 text-white transition-all duration-500 ease-in-out" style={{fontFamily:'Metropolis,sans-serif',fontSize:'20px',whiteSpace:'nowrap'}}>{el.name}</Link>
                                                <p className="text-white" style={{fontFamily:'Metropolis,sans-serif',fontSize:'20px',whiteSpace:'nowrap'}}>{el.title}</p>
                                                <p style={{fontFamily:'Metropolis,sans-serif',fontSize:'14px',color:'#fffc',whiteSpace:'nowrap'}}>{el.designation}</p>
                                            </span>
                                        </span>
                                    </Link>
                                </div>
                            </div>)}
                            </>
                        ))}
                    </TinySlider>
                </div>

                <div className="overflow-hidden after:content-[''] after:absolute after:h-14 after:w-14 after:bg-violet-600/10 dark:after:bg-violet-600/30 after:-top-[50px] after:start-[30%] after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>
            </div>
        </div>
    )
}
