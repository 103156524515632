import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'

import contact from '../assets/images/contact.svg';
import Switcher from '../components/switcher';
import { Link } from 'react-router-dom';
import {FaRegEnvelope, BsTelephone, FiMapPin} from "../assets/icons/vander"
import KifoImg from '../assets/images/kifo-4.jpg'

export default function Contact() {
    return (
        <>
            <Navbar />
            <section className="relative lg:pt-244 pt-[74px] overflow-hidden">
                <div className="container-fluid relative overflow-hidden">
                    <div className="absolute top-0 start-0 w-full h-full z-0 pointer-events-none overflow-hidden" style={{backgroundImage:`url(${KifoImg})`,backgroundPosition:'center',backgroundSize:'cover'}}></div>
                    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
                    <div className="lg:pt-36 lg:pb-48 pt-24 pb-36 shadow dark:shadow-gray-800">
                        <div className="container">
                            <div className="grid grid-cols-1 justify-center text-center">
                                <div className="relative">
                                    <div className="relative mb-5">
                                        <h1 className="font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-white">Contact Us </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <BsTelephone className='text-xxl'/>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title text-xl font-semibold">Phone</h5>
                                {/* <p className="text-slate-400 mt-3">Contact through the given number</p> */}

                                <div className="mt-2">
                                    <Link to="tel:8943018943" className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">8943018943 (President)</Link>
                                </div>
                                <div className="mt-2">
                                    <Link to="tel:9847108855" className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">9847108855 (Secreatry)</Link>
                                </div>
                                <div className="mt-2">
                                    <Link to="tel:7034130243" className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">7034130243 (Treasurer)</Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                               <FaRegEnvelope className='text-xxl'/>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title text-xl font-semibold">Email</h5>
                                <p className="text-slate-400 mt-3">Keep in touch with us</p>

                                <div className="mt-2">
                                    <Link to="mailto:kifootp2020@gmail.com" className="btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">kifootp2020@gmail.com</Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6 mt-6">
                            <div className="w-20 h-20 bg-violet-600/5 text-violet-600 rounded-full text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                <FiMapPin className='text-xxl'/>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title text-xl font-semibold">Location</h5>
                                <p className="text-slate-400 mt-3">KINFRA Defence Park  <br />Lakkdid-Koottupatha, Ottappalam 
                                     <br /> Palakkad 679301
                                    Mangalam (PO)</p>

                                {/* <div className="mt-5">
                                    <Link to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.4265604255734!2d76.42371462408754!3d10.77860540915543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7d8cf3b1f9607%3A0x4d4f08256322468d!2sKinfra%20Park%20Ottappalam%2C%20Palappuram%20-%20Chakkalakundu%20Rd%2C%20Lakkidi%2C%20Lakkidi-perur-%20I%2C%20Kerala%20679301!5e0!3m2!1sen!2sin!4v1722255825922!5m2!1sen!2sin"
                                        data-type="iframe" className="video-play-icon read-more lightbox btn btn-link text-violet-600 hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out">View on Google map</Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container md:mt-24 mt-16">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6 order-1 md:order-2">
                            <img src={contact} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6 mt-8 md:mt-0 order-2 md:order-1">
                            <div className="lg:me-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                                    <h3 className="mb-6 text-2xl leading-snug font-medium">Get in touch !</h3>

                                    <form method="post" name="myForm" id="myForm">
                                        <p className="mb-0" id="error-msg"></p>
                                        <div id="simple-msg"></div>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="name" className="font-semibold">Your Name:</label>
                                                    <input id="name" type="text" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Name :" />
                                                </div>
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="email" className="font-semibold">Your Email:</label>
                                                    <input id="email" type="email" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Email :" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                                    <input id="subject" className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Subject :" />
                                                </div>
                                            </div>

                                            <div className="mb-5">
                                                <div className="text-start">
                                                    <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                    <textarea id="comments" className="form-input w-full text-[15px] py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-2xl outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2" placeholder="Message :"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" id="submit" name="send" className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full justify-center flex items-center">Send Message</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>

            <div className="container-fluid relative mt-12">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe title="iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.4265604255734!2d76.42371462408754!3d10.77860540915543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7d8cf3b1f9607%3A0x4d4f08256322468d!2sKinfra%20Park%20Ottappalam%2C%20Palappuram%20-%20Chakkalakundu%20Rd%2C%20Lakkidi%2C%20Lakkidi-perur-%20I%2C%20Kerala%20679301!5e0!3m2!1sen!2sin!4v1722255825922!5m2!1sen!2sin" style={{ border: 0 }} className="w-full h-[500px]" allowFullScreen></iframe>
                    </div>
                </div>
            </div>

            <Footer />
            <Switcher />
        </>
    )
}
