import item1 from '../assets/images/items/1.jpg';
import item2 from '../assets/images/items/2.gif';
import item3 from '../assets/images/items/2.jpg';
import item4 from '../assets/images/items/3.jpg';
import item5 from '../assets/images/items/3.gif';
import item6 from '../assets/images/items/4.jpg';
import item7 from '../assets/images/items/5.jpg';
import item8 from '../assets/images/items/6.jpg';
import item9 from '../assets/images/items/8.jpg';
import item10 from '../assets/images/items/4.gif';
import item11 from '../assets/images/items/5.gif';
import item12 from '../assets/images/items/9.jpg';
import item13 from '../assets/images/items/1.gif';
import item14 from '../assets/images/items/20.jpg';
import item15 from '../assets/images/items/7.jpg';
import item16 from '../assets/images/items/10.jpg';
import item17 from '../assets/images/items/11.jpg';
import item18 from '../assets/images/items/12.jpg';
import item19 from '../assets/images/items/16.jpg';
import item22 from '../assets/images/items/14.jpg';
import item23 from '../assets/images/items/7.gif';
import item24 from '../assets/images/items/8.gif';
import item25 from '../assets/images/items/15.jpg';
import item26 from '../assets/images/items/17.jpg';
import item27 from '../assets/images/items/18.jpg';
import item28 from '../assets/images/items/19.jpg';

import image1 from '../assets/images/avatar/1.jpg';
import image2 from '../assets/images/avatar/2.jpg';
import image3 from '../assets/images/items/3.gif';
import image4 from '../assets/images/avatar/3.jpg';
import image5 from '../assets/images/avatar/4.jpg';
import image6 from '../assets/images/avatar/5.jpg';
import image7 from '../assets/images/avatar/6.jpg';
import image8 from '../assets/images/items/5.gif';
import image9 from '../assets/images/avatar/7.jpg';
import image10 from '../assets/images/items/5.gif';
import image11 from '../assets/images/avatar/8.jpg';
import image12 from '../assets/images/items/2.gif';

import blog1 from '../assets/images/blog/01.jpg';
import blog2 from '../assets/images/blog/02.jpg';
import blog3 from '../assets/images/blog/03.jpg';
import blog4 from '../assets/images/blog/04.jpg';
import blog5 from '../assets/images/blog/05.jpg';
import blog6 from '../assets/images/blog/06.jpg';
import blog7 from '../assets/images/blog/07.jpg';
import blog8 from '../assets/images/blog/08.jpg';
import blog9 from '../assets/images/blog/09.jpg';
import blog10 from '../assets/images/blog/10.jpg';
import blog11 from '../assets/images/blog/11.jpg';
import blog12 from '../assets/images/blog/12.jpg';
import Sky from '../assets/images/sky.jpg';
import Iconn from '../assets/images/icon-plastics-company.png';
import EastAsian from '../assets/images/Eastasian.jpg';
import Greenvein from '../assets/images/Greenvein.jpg';
import Feromach from '../assets/images/Feromac.jpg';
import Skylark from '../assets/images/skylark-company.jpg';
import Saron from '../assets/images/saronlabs.jpg';
import MicroCloud from '../assets/images/microcloud.jpg';
import Cenova from '../assets/images/cenova.jpeg';

export const data = [
     {
        id:1,
        image: item1,
        subtext: '@StreetBoy',
        title: 'Genuine Undead #3902',
        avatar: image1,
        name:"Steven Townsend",
        date: 'December 29, 2023 6:0:0',
        liveDate:'September 13, 2024 6:0:0',
        category: 'Games',
        subImage:[
            {
                index:1,
                image:item13,
            },
            {
                index:2,
                image:item3,
            },
            {
                index:3,
                image:item4,
            },
        ]
        },
        {
            id:2,
            image: item2,
            subtext: '@CutieGirl',
            title: 'Windchime #768',
            avatar: image2,
            name:"Tiffany Betancourt",
            liveDate:'November 29, 2024 6:0:0',
             category: 'Games',
            subImage:[
                {
                    index:4,
                    image:item1,
                },
                {
                    index:5,
                    image:image12,
                },
                {
                    index:6,
                    image:item6,
                },
            ]
        },
        {
            id:3,
            image: item3,
            subtext: '@ButterFly',
            title: 'Probably A Label #3277',
            avatar: image3,
            name:"Jacqueline Burns",
            liveDate:'March 13, 2024 6:0:0',
            category: 'Art',
            subImage:[
                {
                    index:7,
                    image:item14,
                },
                {
                    index:8,
                    image:item13,
                },
                {
                    index:9,
                    image:item5,
                },
            ]
        }, {
            id:4,
            image: item4,
            subtext: '@NorseQueen',
            title: 'Probably A Label #1711',
            avatar: image4,
            name:"Mari Harrington",
            date: 'January 13, 2024 5:3:1',
            liveDate:'May 6, 2024 6:0:0',
             category: 'Music',
            subImage:[
                {
                    index:10,
                    image:item7,
                },
                {
                    index:11,
                    image:item8,
                },
                {
                    index:12,
                    image:item5,
                },
            ]
        }, {
            id:5,
            image: item5,
            subtext: '@BigBull',
            title: 'Shibuya Scramble Punks',
            avatar: image5,
            name:"Floyd Glasgow",
            liveDate:'July 19, 2024 6:0:0',
            category: 'Video',
            subImage:[
                {
                    index:13,
                    image:item15,
                },
                {
                    index:14,
                    image:item9,
                },
                {
                    index:15,
                    image:item12,
                },
            ]
        }, {
            id:6,
            image: item6,
            subtext: '@Angel',
            title: 'Probably A Label #650',
            avatar: image6,
            name:"Donna Schultz",
            liveDate:'July 20, 2024 6:0:0',
            category: 'Games',
            subImage:[
                {
                    index:16,
                    image:item10,
                },
                {
                    index:17,
                    image:item3,
                },
                {
                    index:18,
                    image:item11,
                },
            ]
        }, {
            id:7,
            image: item7,
            subtext: '@CrazyAnyone',
            title: 'Looki#0147',
            avatar: image7,
            name:"Joshua Morris",
            date: 'December 29, 2023 6:0:0',
            liveDate:'February 17, 2024 6:0:0',
            category: 'Games',
            subImage:[
                {
                    index:19,
                    image:item16,
                },
                {
                    index:20,
                    image:item17,
                },
                {
                    index:21,
                    image:item18,
                },
            ]
        }, {
            id:8,
            image: item8,
            subtext: '@Princess',
            title: 'Poob #285',
            avatar: image8,
            name:"Rosaria Vargas",
            liveDate:'April 15, 2024 6:0:0',
            category: 'Art',
            subImage:[
                {
                    index:22,
                    image:item7,
                },
                {
                    index:24,
                    image:item9,
                },
                {
                    index:24,
                    image:item19,
                },
            ]
        },
        {
            id:9,
            image: item9,
            subtext: '@LooserBad',
            title: 'Umber Arrow',
            avatar: image9,
            name:"Carl Williams",
            liveDate:'May 17, 2024 6:0:0',
            category: 'Music',
            subImage:[
                {
                    index:25,
                    image:item22,
                },
                {
                    index:26,
                    image:item23,
                },
                {
                    index:27,
                    image:item24,
                },
            ]
        },
        {
            id:10,
            image: item10,
            subtext: '@Princess',
            title: 'Gloam Druid',
            avatar: image10,
            name:"Rosaria Vargas",
            liveDate:'August 8, 2024 6:0:0',
             category: 'Memes',
            subImage:[
                {
                    index:28,
                    image:item25,
                },
                {
                    index:29,
                    image:item19,
                },
                {
                    index:30,
                    image:item26,
                },
            ]
        },
        {
            id:11,
            image: item11,
            subtext: '@PandaOne',
            title: 'Azuki #7421',
            avatar: image11,
            name:"Julius Canale",
            liveDate:'December 27, 2024 6:0:0',
             category: 'Memes',
            subImage:[
                {
                    index:31,
                    image:item27,
                },
                {
                    index:32,
                    image:item24,
                },
                {
                    index:33,
                    image:item28,
                },
            ]
        },
        {
            id:12,
            image: item12,
            subtext: '@FunnyGuy',
            title: 'Wolf-Cult Vanguard',
            avatar: image12,
            name:"Michael Williams",
            liveDate:'December 25, 2024 6:0:0',
            category: 'Music',
            subImage:[
                {
                    index:34,
                    image:item7,
                },
                {
                    index:35,
                    image:item14,
                },
                {
                    index:36,
                    image:item19,
                },
            ]
        },
]
export const images = [item13,item3,item4,item1,image12,item6, item14, item13,item5, item7, item8, item5, item15, item9, item12, item10, item3, item11, item16 ,item17, item18, item7, item9, item19, item22, item23, item24, item25, item19, item26, item27, item24, item28, item7, item14, item19  ]

export  const blogData = [
    {
        id:1,
        title: 'Mindfulness Activities for Kids & Toddlers with NFT',
        image: blog1,
        category: 'Arts',
        subtext: '@StreetBoy',
        date:"13th September,2023",
    },
    {
        id:2,
        title: 'Save Thousands Of Lives Through This NFT',
        image: blog2,
        category: 'Illustration',
        subtext: '@CutieGirl',
        date:"29th November, 2023"
    },
    {
        id:3,
        title: 'A place where technology meets craftsmanship',
        image: blog3,
        category: 'Music',
        subtext: '@ButterFly',
        date: "29th December, 2023"
    },
    {
        id:4,
        title: 'NFT Market - A Compact Trike with the Big Benefits',
        image: blog4,
        category: 'Video',
        subtext: '@NorseQueen',
        date:'13th March, 2023',
    },
    {
        id:5,
        title: 'Honoring Black History Month with Toddlers',
        image: blog5,
        category: 'Games',
        subtext: '@@BigBull',
        date:"6th June, 2023"
    },
    {
        id:6,
        title: 'Setting Intentions Instead of Resolutions for 2021',
        image: blog6,
        category: 'Memes',
        subtext: '@Angel',
        date:'19th July, 2023'
    },
    {
        id:7,
        title: 'Clever Ways to Purchase Extraordinart Items',
        image: blog7,
        category: 'GIFs',
        subtext: '@CrazyAnyone',
        date:"20th July, 2023"
    },
    {
        id:8,
        title: 'How to Save Money on Baby Essentials for NFT',
        image: blog8,
        category: 'Video',
        subtext: '@Princess',
        date:"31st August, 2023"
    },
    {
        id:9,
        title: 'Liki Trike - A Compact Trike with the Big Benefits',
        image: blog9,
        category: 'GIFs',
        subtext: '@CrazyAnyone',
        date:"13th September,2023",
    },
    {
        id:10,
        title: 'NFT Market - A Compact the Big Benefits',
        image: blog10,
        category: 'Tech',
        subtext: '@Princess',
        date:"29th November, 2023"
    },
    {
        id:11,
        title: 'Behind the Scenes of the creabik App',
        image: blog11,
        category: 'Arts',
        subtext: '@PandaOne',
        date:'13th March, 2023',
    },
    {
        id:12,
        title: 'Meet fennouni, Product Designer at GitHub',
        image: blog12,
        category: 'GIFs',
        subtext: '@FunnyGuy',
        date:'19th July, 2023'
    }
]
export  const industryData = [
    {
        id:1,
        title: 'skyniche Technologies',
        image: Sky,
        category: 'Information Technology',
        title1:'skyniche-technologies',
        services:"Our Services",
        servicePoints:[
            "Web development",
            "Digital marketing",
            "IT consulting",
            "Online business solutions and services",
            "Enhancing online presence",
            "Improving operational efficiency",
            "Providing scalable and sustainable solutions",
            "Driving growth and innovation",
        ],
        website:'https://www.skyniche.com',
        description:'skyniche is an Indian multinational corporation specializing in IT services. These services encompass digital and internet business solutions, consulting, and e-commerce solutions. The company is based in Palakkad, Kerala, India. With over a decade of experience, skyniche has been instrumental in assisting start-ups, businesses, and organizations in realizing their visions.',
        description1:'With more than 10 years of expertise, skyniche can aid you in achieving your objectives by enhancing your brand through a strong web presence. Their extensive experience in IT services and digital solutions positions them as a valuable partner in advancing your business goals and improving your online visibility.',
    },
    {
        id:2,
        title: 'East Asian Holdings',
        image: EastAsian,
        title1:'east-asian-holdings',
        category: 'Manufacturing',
        services:"Our Services",
        servicePoints:[
            "Soap Manufacturing",
            "Cosmetics Manufacturing",
            "Partnership and Customization",
            "Competitive pricing",
            "Detergent Manufacturing",
        ],
        website:'https://eastasian.in',
        description:'We, East Asian Holdings, are a Fast Moving Consumer Goods (FMCG) Group based in Kerala, which focuses on manufacturing and marketing of Soaps, Cosmetics and Detergents since 2014. The firm embodies Mr.ABDULMANAF T A (Founder and Proprietor) dream of enhanced quality of living through quality products.',
        description1:'Our brands could earn commendable market rapport within a very short period itself. With an annual growth rate of 8% and we crossed its turnover of of Rs. 56 million in the last two years [2020-21 & 2021-22]. Reckoning the growing demand of our products in the market, we set up an automated toilet soaps and cosmetics manufacturing unit with an installed capacity of 300 Mt/month in the KINFRA Integrated Industrial Park, Ottapalam, Kerala – 679103 in January 2020, We have done three exports to two countries Oman (Middle East) two times and Peru (South America). The company got the ISO 9001:2015 Certification and aims at achieving an annual turnover of Rs. 100 million in near future.',
    },
    {
        id:3,
        title: 'Iconn Plastics',
        image: Iconn,
        category: 'Manufacturing',
        title1:'iconn-plastics',
        services:"Our Services",
        servicePoints:[
            "Diverse Range of Plastic Products",
            "Commitment to Quality and Innovation",
            "Experienced Professionals",
            "Experienced Professionals",
            "Reliability and Performance",
        ],
        // website:'https://eastasian.in',
        description:'Iconn Plastics in Palakkad Ponnani Road, Palakkad is known to satisfactorily cater to the demands of its customer base. It stands located at Kinfra Integrated Industrial Park, Unit No. Kiip, 13, Lakkidi Koottupatha, Ottapalam, Palakkad Ponnani Road-679103. Ottapalam is a prominent landmark in the area and this establishment is in close proximity to the same.The business strives to make for a positive experience through its offerings.',
        description1:'Selecting the right provider for plastic products is crucial for meeting diverse needs with quality and reliability. In the realm of plastic products, Iconn Plastics emerges as a symbol of excellence, dedicated to delivering top-notch services for various requirements. Their commitment to perfection and years of experience ensure that your quest for reliable plastic products concludes here. Whether you are looking for practical additions, aesthetic enhancements, or custom solutions, trust Iconn Plastics to provide the best-in-class plastic product solutions.',
    },
    {
        id:4,
        title: 'Feromach Technology',
        image: Feromach,
        title1:'feromach-technology',
        category: 'Manufacturing',
        services:"Our Services",
        servicePoints:[
            "Matching Jobs",
            "Sheet Metal Fabrication",
            "3D Printing",
            "Surface Grindling",
            "Cylindrical Grindling",
        ],
        // website:'https://eastasian.in',
        description:"A group of engineers, both young and experienced, have come together with a shared passion for tools, modules, design, manufacturing, and production. They are dedicated to utilizing their expertise and knowledge in these fields to create innovative solutions and products. The team's combined experience and skill set allow them to approach projects from a variety of angles, providing unique and well-rounded solutions. Their enthusiasm for the work they do drives them to constantly push boundaries and explore new avenues for improvement. Our focus on the quality and efficiency of a team of engineers is committed to delivering exceptional results to their clients. They strive to ensure that each project they undertake is completed on time, within budget, and to the highest standard of excellence.",
        description1:"At Feromach, we provide a comprehensive solution for all types of tool, module, and fixture-related jobs. we excel at providing custom fabrication solutions that meet the needs of clients from diverse industries. Our team of experts leverages state-of-the-art equipment and cutting-edge technology to deliver high-quality products that meet the unique specifications of each client. Whether it's aerospace, construction, or medical devices, we take pride in delivering fabrication solutions that exceed expectations.",
    },
    {
        id:6,
        title: 'Skylark Plastics',
        image: Skylark,
        title1:'skylark-plastics',
        category: 'Manufacturing',
        services:"Our Services",
        servicePoints:[
            "Diverse Range of Plastic Products",
            "Commitment to Quality",
            "Focus on Innovation",
            "Experienced Professional Team",
            "Reliability",
        ],
        // website:'https://eastasian.in',
        description:"Skylark Plastics in Lakkidi, Palakkad is known to satisfactorily cater to the demands of its customer base. It stands located at Lakkidi Kootupatha, Ottapalam, Lakkidi-679301.The business strives to make for a positive experience through its offerings. ",
        description1:"Selecting the right provider for plastic products is crucial for meeting diverse needs with quality and reliability. In the realm of plastic products, Skylark Plastics emerges as a symbol of excellence, dedicated to delivering top-notch services for various requirements. Their commitment to perfection and years of experience ensure that your quest for reliable plastic products concludes here. Whether you are looking for practical additions, aesthetic enhancements, or custom solutions, trust Skylark Plastics to provide the best-in-class plastic product solutions.",
    },
    {
        id:7,
        title: 'Green Vein',
        image: Greenvein,
        title1:'green-vein',
        category: 'Manufacturing',
        // services:"Our Services",
        // servicePoints:[
        //     "Matching Jobs",
        //     "Sheet Metal Fabrication",
        //     "3D Printing",
        //     "Surface Grindling",
        //     "Cylindrical Grindling",
        // ],
        // website:'https://eastasian.in',
        // description:"A group of engineers, both young and experienced, have come together with a shared passion for tools, modules, design, manufacturing, and production. They are dedicated to utilizing their expertise and knowledge in these fields to create innovative solutions and products. The team's combined experience and skill set allow them to approach projects from a variety of angles, providing unique and well-rounded solutions. Their enthusiasm for the work they do drives them to constantly push boundaries and explore new avenues for improvement. Our focus on the quality and efficiency of a team of engineers is committed to delivering exceptional results to their clients. They strive to ensure that each project they undertake is completed on time, within budget, and to the highest standard of excellence.",
        // description1:"At Feromach, we provide a comprehensive solution for all types of tool, module, and fixture-related jobs. we excel at providing custom fabrication solutions that meet the needs of clients from diverse industries. Our team of experts leverages state-of-the-art equipment and cutting-edge technology to deliver high-quality products that meet the unique specifications of each client. Whether it's aerospace, construction, or medical devices, we take pride in delivering fabrication solutions that exceed expectations.",
    },
    {
        id:8,
        title: 'Micorclouds',
        image: MicroCloud,
        title1:'micorclouds',
        category: 'Information Technology',
        services:"Our Services",
        servicePoints:[
            "Digital Experience",
            "Applied AI",
            "Mobile Application Development",
            "Realworld IOT",
            "Consulting",
        ],
        website:'https://microclouds-india.com/',
        description:"We microclouds are one of the india’s leading software and technology company. our experience and infrastructure ensures that the technology we design, develop and optimize exceed our client's expectations. We also firmly believe in excellent communication with our clients. From an initial, no-obligation consultation with one of our business develop- ment managers, to your assigned technology designer — who is always just a phone call away.",
        description1:"We deliver large promises, our technology solutions touched millions of lifes and achived the grace of suc- cess.we are looking forward to deliver much larger im- possible problems to be solved",
    },
    {
        id:9,
        title: 'Saron Labs',
        image: Saron,
        title1:'saron-labs',
        category: 'Information Technology',
        services:"Our Services",
        servicePoints:[
            "Research & Development of IOT products.",
            "Providing customized product solutions.",
            "3D Modeling with 3D printing & laser engraving.",
            "Design and development of mechatronics systems.",
        ],
        website:'https://www.saron.in/',
        description:"SARON INNOVATURE LABS LLP was founded in 2016, focuses on research, development and manufacturing of products in Home Automation and IoT sector. We have established a strong reputation for providing high quality, reliable products for our customers. Our team of experienced R&D team, technicians and support staffs have the expertise in design, development and manufacture a wide range of electronic devices. We are providing the best customer service and always strive to exceed our customers' expectations. Our team of engineers are always on hand to provide technical assistance to the valuable customers. We also offer comprehensive warranty and maintenance services to ensure that products are always functioning optimally. We are located at KINFRA INTEGRATED INDUSTRIAL PARK & KINFRA DEFENCE PARK, Palakkad, Kerala.",
        description1:"To become a global company which delivers top quality energy saving and IoT products to our customers.Consistently provide quality and good value to our customers through best products and services by adopting the latest technology, research and development.",
    },
    {
        id:10,
        title: 'Cenova Packaging',
        image: Cenova,
        title1:'cenova-packaging',
        category: 'Manufacturing',
        services:"Our Services",
        servicePoints:[
            "Plastic Chemical Bottles",
            "Edible Bottles",
            "Food Bottles and Containers",
            "HDPE Bottles",
            "Mineral Bottles",
            "PET Jar Bottles",
            "Pharma Bottles",
            "Soda Bottles",
        ],
        website:'https://www.cheeni.in/',
        description:"Cenova Packaging is a premier supplier and trader specializing in a comprehensive range of plastic packaging solutions. Based in Ottapalam, Kerala, our company is dedicated to offering top-quality bottles and containers that cater to a variety of industrial and consumer needs. We Specializes in trading a wide array of plastic bottles and containers designed to meet diverse industry needs. With a commitment to excellence and innovation, we have established ourselves as a trusted name in the packaging industry.",
        description1:"At Cenova Packaging, we pride ourselves on our customer-centric approach and our ability to deliver tailored packaging solutions that meet the unique requirements of each client. Our extensive product range, combined with our commitment to quality and reliability, ensures that we provide exceptional value in every transaction.",
    },
    // {
    //     id:5,
    //     title: 'Honoring Black History Month with Toddlers',
    //     image: blog5,
    //     category: 'Games',
    //     subtext: '@@BigBull',
    //     date:"6th June, 2023"
    // },
    // {
    //     id:6,
    //     title: 'Setting Intentions Instead of Resolutions for 2021',
    //     image: blog6,
    //     category: 'Memes',
    //     subtext: '@Angel',
    //     date:'19th July, 2023'
    // },
    // {
    //     id:7,
    //     title: 'Clever Ways to Purchase Extraordinart Items',
    //     image: blog7,
    //     category: 'GIFs',
    //     subtext: '@CrazyAnyone',
    //     date:"20th July, 2023"
    // },
    // {
    //     id:8,
    //     title: 'How to Save Money on Baby Essentials for NFT',
    //     image: blog8,
    //     category: 'Video',
    //     subtext: '@Princess',
    //     date:"31st August, 2023"
    // },
    // {
    //     id:9,
    //     title: 'Liki Trike - A Compact Trike with the Big Benefits',
    //     image: blog9,
    //     category: 'GIFs',
    //     subtext: '@CrazyAnyone',
    //     date:"13th September,2023",
    // },
    // {
    //     id:10,
    //     title: 'NFT Market - A Compact the Big Benefits',
    //     image: blog10,
    //     category: 'Tech',
    //     subtext: '@Princess',
    //     date:"29th November, 2023"
    // },
    // {
    //     id:11,
    //     title: 'Behind the Scenes of the creabik App',
    //     image: blog11,
    //     category: 'Arts',
    //     subtext: '@PandaOne',
    //     date:'13th March, 2023',
    // },
    // {
    //     id:12,
    //     title: 'Meet fennouni, Product Designer at GitHub',
    //     image: blog12,
    //     category: 'GIFs',
    //     subtext: '@FunnyGuy',
    //     date:'19th July, 2023'
    // }
]